import React from 'react';
import { useHistory } from "react-router-dom";
import {
  SearchRoute
} from '../utils/routes';
import {
  SearchPage,
  LoginPage,
  PrintSetupInstructionsPage
} from '../views';

const LoginRouter = () => {
  const history = useHistory();

  const onSuccess = () => {
    history.push(window.GLOBAL_PATH + SearchRoute);
  }

  return <LoginPage successFunc={onSuccess} />;
}

const SearchRouter = () => {
  return <SearchPage />;
}

const PrintSetupInstructionsRouter = () => {
  return <PrintSetupInstructionsPage />;
}

export {
  LoginRouter,
  SearchRouter,
  PrintSetupInstructionsRouter
}
