
import { useMemo } from 'react';
import { useGet, useTriggeredGet, usePost, useTriggeredPost } from "./";

// Login

const useProfile = () => {
    const { response, error, isLoading } = useGet({ url: `/admin/profile` });
    const profile = useMemo(() => response || {}, [response]);
    return { profile, error, isLoading };
}

const useTriggeredProfile = () => {
    const { endpoint: useProfile, isLoading } = useTriggeredGet({ url: `/admin/profile` });
    return { useProfile, isLoading };
}

// Fetch Oauth Prioviders

const useOauthProviders = () => {
    const { response, error, isLoading } = useGet({ url: `/admin/login/idps` });
    const oauthProviders = useMemo(() => response || {}, [response]);
    return { oauthProviders, error, isLoading };
}

const useTriggeredOauthProviders = () => {
    const { endpoint: useOauthProviders, isLoading } = useTriggeredGet({ url: `/admin/login/idps` });
    return { useOauthProviders, isLoading };
}

// Logout

const useLogout = () => {
    const { response, error, isLoading } = usePost({ url: `/admin/logout` });
    const logout = useMemo(() => response || {}, [response]);
    return { logout, error, isLoading };
}

const useTriggeredLogout = () => {
    const { endpoint: useLogout, isLoading } = useTriggeredPost({ url: `/admin/logout` });
    return { useLogout, isLoading };
}

export {
    useProfile,
    useTriggeredProfile,
    useLogout,
    useTriggeredLogout,
    useOauthProviders,
    useTriggeredOauthProviders,
}
