export enum AccountStatus {
  ACTIVE = 'ACTIVE',
  PLACEHOLDER = 'PLACEHOLDER',
}

export enum VerificationStatus {
  VERIFIED = 'VERIFIED',
  UNVERIFIED = 'UNVERIFIED',
  PENDING_REVIEW = 'PENDING_REVIEW',
}
