import parse from "html-react-parser";
import React, { useEffect, useState } from "react";
import {
  Button,
  Header,
  ModalComp,
  Notification,
  SearchForm,
} from "../../components";
import {
  ConfigI,
  Features,
  SearchInputI,
  SearchResultI,
} from "../../components/ComponentInterface";
import { validateEmail } from "../../components/validate";
import { useContext } from "../../context";
import { useTriggeredSearch, useTriggeredFetchVerification, useTriggeredStartVerification, useTriggeredFetchVerificationCode, useTriggeredFetchValidationBrokerData, useTriggeredGetPersonDetails } from "../../services/AdminServices";
import { SESSION_TIMEOUT, API_ERROR, VERIFICATION_CODE_EXPIRED } from "../../utils/Constants";
import { PrintSetupInstructionsRoute } from "../../utils/routes";
import { updatedDataFromValidator } from "../../utils/SearchPage";
import useHealthCardVerification from './hooks/useHealthCardVerification';
import { AccountStatus, VerificationStatus } from '../../common/enum';
import { updateError } from "../../context/actions/ConfigActions";
import i18n from "../../i18n";
import Reset2FAModal from "./Components/modal/Reset2FAModal";
import ResetEmailModal from "./Components/modal/ResetEmailModal";
import SearchResultTable from "./Components/SearchResultTable";
import moment from "moment";

const convertSecondsToHumanReadableTime = (seconds: number) =>
  moment.duration(seconds, "seconds").humanize();

const SearchPage = () => {
  const {
    state: { error, config },
    dispatch,
  }: {
    state: { error: any; config: ConfigI };
    dispatch: any;
  } = useContext();
  const [searchData, setSearchData] = useState<SearchInputI>({
    rin: "",
    email: "",
  });

  const { useSearch: searchApi, isLoading } = useTriggeredSearch(searchData);
  const [searchResults, setSearchResults] = useState<Array<SearchResultI>>([]);

  const {
    useFetchVerification: fetchVerification,
    isLoading: isLoadingFetchVerification,
  } = useTriggeredFetchVerification(searchResults[0]?.verification_id);
  const {
    useStartVerification: startVerification,
    isLoading: isLoadingStartVerification,
  } = useTriggeredStartVerification();
  const {
    useFetchVerificationCode: fetchVerificationCode,
    isLoading: isLoadingFetchVerificationCode,
  } = useTriggeredFetchVerificationCode(searchResults[0]?.verification_id);
  const {
    useFetchValidationBrokerData: fetchValidationBrokerData,
    isLoading: isLoadingFetchValidationBrokerData,
  } = useTriggeredFetchValidationBrokerData(searchResults[0]?.user_id);


  const { redirectionIDVHealthCard, isLoadingHC } = useHealthCardVerification(searchResults[0]?.user_id)
  const { useGetPersonDetails: getPersonDetails } = useTriggeredGetPersonDetails(searchResults[0]?.user_id);
  const [showAddHealthCard, setShowAddHealthCard] = useState<boolean>(false);
  const [validationBrokerData, setValidationBrokerData] = useState<any>({});

  // modal
  const [isConfirmDetailsModalOpen, setConfirmDetailsModalOpen] =
    useState(false);
  const [isReset2FAModalOpen, setReset2FAModalOpen] = useState(false);
  const [isResetEmailModalOpen, setResetEmailModalOpen] = useState(false);

  const [searchingError, setSearchingError] = useState<boolean>(false);
  const [isSearching, setIsSearching] = useState<boolean>(false);

  const [errorObj, setErrorObj] = useState<any>({});

  const handleIDVHealthCard = async () => {
      await redirectionIDVHealthCard()
      setSearchData({rin: '', email: ''})
      setSearchResults([])
  }

  // Close MOdals on Error
  useEffect(() => {
    if (error?.hasError) {
      setConfirmDetailsModalOpen(false);
    }

    // eslint-disable-next-line
  }, [error?.hasError]);

  useEffect(() => {
    if(searchResults[0]?.verification_status?.toUpperCase() === VerificationStatus.VERIFIED) {
        getPersonDetails().then(personalDetails => {
            const {response} = personalDetails
            if(response.isSuccess) {
                const {data: { health_card_number }} = response
                setShowAddHealthCard(!!!health_card_number)
            } else {
                setShowAddHealthCard(false)
            }
     })
    }
  // eslint-disable-next-line 
  }, [searchResults[0]?.user_id, searchResults[0]?.verification_status])

  // Handle Searching Api call trigger
  useEffect(() => {
    if (isSearching) {
      handleFetchSearchApi();
    }

    // eslint-disable-next-line
  }, [isSearching]);

  // Update Data from fetched json
  useEffect(() => {
    const dataFromValidator = () => {
      const result = updatedDataFromValidator(
        searchResults[0],
        validationBrokerData
      );
      // Update the validator fetched data
      const updatedResults = searchResults.map((item) =>
        item.user_id === result.user_id ? result : item
      );

      setSearchResults(updatedResults);
    };

    if (Object.keys(validationBrokerData).length) {
      dataFromValidator();
    }

    // eslint-disable-next-line
  }, [validationBrokerData]);

  const handleReset2FAModalClose = () => {
    handleFetchSearchResults();
    setReset2FAModalOpen(false);
  };

  const handleResetEmailModalClose = (newEmail?: string) => {
    setSearchingError(false);
    setIsSearching(true);
    let formattedData = searchData;
    if (newEmail) formattedData.email = newEmail;
    setSearchData(formattedData);
    setResetEmailModalOpen(false);
  };

  const handleEditClick = (key: string) => {
    switch (key) {
      case "email":
        setResetEmailModalOpen(true);
        break;
      default:
        break;
    }
  };

  // Search Click
  const handleFetchSearchResults = async () => {
    setSearchingError(false);
    setIsSearching(true);

    let formattedData = searchData;

    if (!searchData?.email) {
      formattedData = {
        rin: searchData?.rin,
      };
    }

    setSearchData(formattedData);
  };

  // Api Call to Fetch the Search Result
  const handleFetchSearchApi = async () => {
    try {
      const { response, error: api_error } = await searchApi();

      if (response?.isSuccess) {
        setSearchResults(response?.data);
      }

      if (Object.keys(api_error).length) {
        if (api_error.isUnauthorizedError || api_error.isForbiddenError) {
          dispatch(updateError({ hasError: true, type: SESSION_TIMEOUT }));
        } else {
          dispatch(updateError({ hasError: true, type: API_ERROR }));
        }
      }
    } catch (err) {
      console.log("err", err);
      dispatch(updateError({ hasError: true, type: API_ERROR }));
    } finally {
      setSearchingError(true);
      setIsSearching(false);
    }
  };

  // Modal helper MEthods
  const toggleConfirmDetailsModal = () =>
    setConfirmDetailsModalOpen(!isConfirmDetailsModalOpen);

  // PLaceholder account confirmation modal
  const handleCreateAccountModal = () => {
    // Error Cases foe email
    // If email is empty
    if (!searchData?.email) {
      return setErrorObj((prevInputs: any) => ({
        ...prevInputs,
        email: {
          hasError: true,
          errorMessage: "SearchPage.ErrorMessages.required-field",
        },
      }));
    } else {
      // Valid Email Check
      if (!validateEmail(searchData?.email)) {
        return setErrorObj((prevInputs: any) => ({
          ...prevInputs,
          email: {
            hasError: true,
            errorMessage: "SearchPage.ErrorMessages.invalid-email",
          },
        }));
      }
    }

    setConfirmDetailsModalOpen(true);
  };

  // Api Call to verfication for UNVERIFIED USER
  // Api Call for Creating a Placeholder Account
  const handleStartVerification = async () => {
    try {
      let formattedData: any = {
        user_id: searchResults[0]?.user_id,
        email: searchResults[0]?.email,
        rin: searchData?.rin,
      };

      // For placeholder Account dont add user_id
      if (!searchResults.length) {
        formattedData = {
          ...searchData,
        };
      }

      const { response, error: api_error } = await startVerification({
        data: formattedData,
      });

      if (response?.isSuccess) {
        // Open Link in a new tab
        window.open(response?.data?.link, "_blank");
      }

      if (Object.keys(api_error).length) {
        if (api_error.isUnauthorizedError || api_error.isForbiddenError) {
          dispatch(updateError({ hasError: true, type: SESSION_TIMEOUT }));
        } else {
          dispatch(updateError({ hasError: true, type: API_ERROR }));
        }
      }
    } catch (err) {
      dispatch(updateError({ hasError: true, type: API_ERROR }));
    } finally {
      // Close Placeholder on done
      setConfirmDetailsModalOpen(false);
    }
  };

  // Api Call to verfication for PENDING REVIEW
  const handleFetchVerification = async () => {
    try {
      const { response, error: api_error } = await fetchVerification();

      if (response?.isSuccess) {
        // Open Link in a new tab
        window.open(response?.data?.link, "_blank");
      }

      if (Object.keys(api_error).length) {
        if (api_error.isUnauthorizedError || api_error.isForbiddenError) {
          dispatch(updateError({ hasError: true, type: SESSION_TIMEOUT }));
        } else {
          dispatch(updateError({ hasError: true, type: API_ERROR }));
        }
      }
    } catch (err) {
      dispatch(updateError({ hasError: true, type: API_ERROR }));
    }
  };

  // Api Call to to FETCH SWAT DATA
  const handleFetchValidationBrokerData = async () => {
    try {
      const { response, error: api_error } = await fetchValidationBrokerData();

      if (response?.isSuccess) {
        setValidationBrokerData(response?.data);
      }

      if (Object.keys(api_error).length) {
        if (api_error.isUnauthorizedError || api_error.isForbiddenError) {
          dispatch(updateError({ hasError: true, type: SESSION_TIMEOUT }));
        } else {
          dispatch(updateError({ hasError: true, type: API_ERROR }));
        }
      }
    } catch (err) {
      dispatch(updateError({ hasError: true, type: API_ERROR }));
    }
  };

  // Api Call to FETCH verification code for printing
  const handleFetchVerificationCode = async () => {
    try {
      const { response, error: api_error } = await fetchVerificationCode();

      if (response?.isSuccess) {
        // Check Code Expiry
        if (response?.data?.expires_at > Date.now()) {
          // Open Link in a new tab
          // Add the data in the url params
          window.open(
            window.location.origin +
              window.GLOBAL_PATH +
              `${PrintSetupInstructionsRoute}?first_name=${response?.data?.first_name}&code=${response?.data?.code}&expiry_date=${response?.data?.expires_at}`,
            "_blank"
          );
        } else {
          // Open Code Expired Modal
          dispatch(
            updateError({ hasError: true, type: VERIFICATION_CODE_EXPIRED })
          );
        }
      }

      if (Object.keys(api_error).length) {
        if (api_error.isUnauthorizedError || api_error.isForbiddenError) {
          dispatch(updateError({ hasError: true, type: SESSION_TIMEOUT }));
        } else {
          dispatch(updateError({ hasError: true, type: API_ERROR }));
        }
      }
    } catch (err) {
      dispatch(updateError({ hasError: true, type: API_ERROR }));
    }
  };

  const handleActionableBtns = () => {
    // Unverified Case
    const [searchResult] = searchResults
    const { account_status, verification_status} = searchResult

    if (verification_status?.toUpperCase() === VerificationStatus.UNVERIFIED) {
      return (
        <Button
          text={i18n.t("SearchPage.SearchLabels.verify-identity-btn")}
          onClick={() => handleStartVerification()}
          className="create-account-btn"
          isLoading={isLoadingStartVerification}
          dataTestId={"button-verify-identity-btn"}
        />
      );
    }

    // Pending Review Case
    if (verification_status?.toUpperCase() === VerificationStatus.PENDING_REVIEW) {
      return (
        <Button
          text={i18n.t("SearchPage.SearchLabels.verify-identity-btn")}
          onClick={() => handleFetchVerification()}
          className="create-account-btn"
          isLoading={isLoadingFetchVerification}
          dataTestId={"button-verify-identity-btn"}
        />
      );
    }

    // Verified Case
    if (verification_status?.toUpperCase() === VerificationStatus.VERIFIED) {
      return (
        <>
          {
          account_status?.toUpperCase() === AccountStatus.ACTIVE && showAddHealthCard &&
          <Button
              text={i18n.t("SearchPage.SearchLabels.verify-healthcard-btn")}
              onClick={handleIDVHealthCard}
              className='create-account-btn'
              isLoading={isLoadingHC}
              dataTestId={"button-verify-hc"}
            />
          }

          <Button
            text={i18n.t("SearchPage.SearchLabels.update-data-from-swat-btn")}
            onClick={() => handleFetchValidationBrokerData()}
            className="create-account-btn"
            isLoading={isLoadingFetchValidationBrokerData}
            dataTestId={"update-from-swat"}
          />

          {/* USer Setup Required Case */}

          {account_status?.toUpperCase() === AccountStatus.PLACEHOLDER ? (
            <Button
              text={i18n.t("SearchPage.SearchLabels.print-instructions-btn")}
              onClick={() => handleFetchVerificationCode()}
              className="create-account-btn"
              isLoading={isLoadingFetchVerificationCode}
              dataTestId={"print-instructions"}
            />
          ) : null}
        </>
      );
    }
  };

  return (
    <>
      <Header title={i18n.t("SearchPage.title")} showLogout />
      <div className={"searchpage"}>
        <Notification
          className="alert-msg"
          text={parse(i18n.t("SearchPage.alertMessage"))}
        />

        <section className="search">
          <SearchForm
            successFunc={handleFetchSearchResults}
            isLoading={isLoading}
            isSearchingSetState={setSearchingError}
            errorObj={errorObj}
            setErrorObj={setErrorObj}
            inputs={searchData}
            setInputs={setSearchData}
          />
        </section>

        <section className="search-results">
          <SearchResultTable
            data={searchResults}
            emptyMessage={i18n.t("SearchPage.no-result")}
            emptyBtnAction={handleCreateAccountModal}
            searchingError={searchingError}
            validationBrokerData={validationBrokerData}
            handleEditClick={handleEditClick}
          />

          {/* Actionable Buttons */}

          {searchResults?.length ? (
            <div className="btns-container">
              {!!config.feature_flags_enabled?.[Features.Reset2FA] &&
                searchResults?.[0]?.account_status === "ACTIVE" &&
                (!!searchResults?.[0]?.["2fa_reset"] || searchResults?.[0]?.authenticator !== 'EMAIL') &&
                !searchResults?.[0]?.email_reset && (
                  <Button
                    text={i18n.t("SearchPage.SearchLabels.reset-2fa-btn")}
                    onClick={() => setReset2FAModalOpen(true)}
                    className="create-account-btn red-btn"
                    dataTestId="reset-2fa"
                  />
                )}
              {handleActionableBtns()}
            </div>
          ) : null}
        </section>
      </div>

      <ModalComp
        toggleModal={toggleConfirmDetailsModal}
        isModalOpen={isConfirmDetailsModalOpen}
      >
        <div className="confirm-details-modal" data-testid="confirm-details">
          {parse(
            i18n.t("SearchPage.confirm-details-modal", {
              rin: searchData?.rin,
              email: searchData?.email,
            })
          )}

          <Button
            text={i18n.t("continue")}
            onClick={handleStartVerification}
            isLoading={isLoadingStartVerification}
            dataTestId={"button-primary"}
          />
        </div>
      </ModalComp>
      <Reset2FAModal
        userId={searchResults?.[0]?.user_id}
        resetExpiryDuration={
          !!searchResults?.[0]?.duration
            ? convertSecondsToHumanReadableTime(searchResults[0].duration)
            : undefined
        }
        isOpen={isReset2FAModalOpen}
        close={handleReset2FAModalClose}
      />
      <ResetEmailModal
        userId={searchResults?.[0]?.user_id}
        userEmail={searchResults?.[0]?.email}
        isOpen={isResetEmailModalOpen}
        resetExpiryDuration={
          !!searchResults?.[0]?.duration
            ? convertSecondsToHumanReadableTime(searchResults[0].duration)
            : undefined
        }
        close={handleResetEmailModalClose}
      />
    </>
  );
};

export default SearchPage;
