import React, {
  useEffect,
  useState
} from 'react';
import {
  Layout,
  Spinner
} from "./components";
import { useContext } from './context'
import './styles/App.css';
import {
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';
import {
  updateConfig,
  updateError,
  updateProfile
} from './context/actions/ConfigActions';
import './styles/App.scss';
import Modal from 'react-modal';
import {
  SearchRoute,
  PrintSetupInstructionsRoute
} from './utils/routes';
import {
  LoginRouter,
  SearchRouter,
  PrintSetupInstructionsRouter
} from './router';
import ProtectedRoute, {
  DefaultRoute,
  PublicRoute,
} from './utils/RouteTypes';
import { useTranslation } from 'react-i18next';
import { 
  useTriggeredProfile,
} from './services/LoginServices';
import { API_ERROR } from "./utils/Constants";
import { getConfig, getHash } from './services';

function App() {
  const [ready, setReady] = useState(false);
  const { dispatch, state: { profile } } = useContext();
  const { useProfile: profileApi } = useTriggeredProfile();

  const [isApiDone, setIsApiDone] = useState<boolean>(false);
  const { i18n } = useTranslation();
  
  // Initialize i18n
  // Get Config
  useEffect(() => {
    const getConfigSetup = async () => {
      const portrait = window.matchMedia('(orientation: portrait)');
      const landscape = window.matchMedia('(orientation: landscape)');
      const configData = (await getConfig())?.data

      dispatch(updateConfig(configData));

      getHash("error") && dispatch(updateError({ hasError: true, type: getHash("error") }));

      Modal.setAppElement('#root');
      portrait.addEventListener('change', function (e) { if (e.matches) { window.scrollTo({ top: 0, behavior: 'smooth' }) } })
      landscape.addEventListener('change', function (e) { if (e.matches) { window.scrollTo({ top: 0, behavior: 'smooth' }) } })
    }
    
    getConfigSetup();
    window.addEventListener('resize', function () { calcViewportUnits() })
    calcViewportUnits();
    // eslint-disable-next-line 
  }, [])

  const calcViewportUnits = () => document.documentElement.style.setProperty('--vh', window.innerHeight * 0.01 + 'px');

  // Load Translations
  useEffect(() => {
    const initialize = async () => {
      await i18n.init();

      setReady(true)
    }

    initialize();
    // eslint-disable-next-line 
  }, [i18n]);

  // GEt profile Api Call
  useEffect(() => { 
    const getProfileApiPromise = async () => {
      await Promise.resolve(getProfileApi());

      setIsApiDone(true);
    }

    if (ready) {
      !profile  ? getProfileApiPromise() : setIsApiDone(true);
    }

    // eslint-disable-next-line
  }, [profile, ready]);

  const getProfileApi = () => {
    return new Promise<void>(async (resolve, reject) => { 
      try { 
        const { response, error } = await profileApi();
        
        if (response.isSuccess && typeof response?.data === 'object') {
          dispatch(updateProfile(response?.data))
        }

        if (Object.keys(error).length) {
          if (!(error?.isForbiddenError || error.isUnauthorizedError)) {
            dispatch(updateError({ hasError: true, type: API_ERROR }));
          }
        }          
      } catch (e) {
        console.log('e', e);
      } finally {
        resolve();
      }
    })
  };

  if (!ready || !isApiDone || !i18n.isInitialized) {
    return <div className="center-page"><Spinner dark /></div>;
  };
  
  return (
    <Router>
      <Layout>
        <Switch>
          {/* Login Page */}
          <PublicRoute exact path={window.GLOBAL_PATH} render={() => <LoginRouter />} /> 

          {/* Search Page */}
          <ProtectedRoute exact path={window.GLOBAL_PATH + SearchRoute} render={() => <SearchRouter  />} />

          <PublicRoute exact path={window.GLOBAL_PATH + PrintSetupInstructionsRoute} render={() => <PrintSetupInstructionsRouter />} />
          
          {/* For the invalid urls redirect to window.GLOBAL_PATH */}
          <DefaultRoute />
        </Switch>

      </Layout>
    </Router>
  );
}

export default App;
