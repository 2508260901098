import React, { useState, useEffect, useRef } from 'react';
import InputMask from 'react-input-mask';

const AutoWidthInput = ({
  initialValue = "",
  mask,
  readOnly
}: { initialValue?: string, mask: string, readOnly?: boolean }) => {
  const maskInputRef: any = useRef(null);
  const spanRef = useRef<HTMLSpanElement>(null);
  const [maskedValue, setMaskedValue] = useState(initialValue);
  const [inputWidth, setInputWidth] = useState("auto");

  const updateWidth = () => {
    if (spanRef.current) {
        const spanWidth = spanRef.current.offsetWidth;
        const additionalWidthInCh = 2; 
        const chWidth = spanWidth / 8;
        setInputWidth(`calc(${chWidth + additionalWidthInCh}ch)`);
      }
  };

  useEffect(() => {
    setMaskedValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (mask && maskInputRef.current) {
      setMaskedValue(maskInputRef.current.value);
    }
  }, [mask]);

  useEffect(() => {
    updateWidth();
  }, [maskedValue]);

  useEffect(() => {
    updateWidth();
  }, [maskedValue, initialValue]);

  return (
    <div className="auto-width-input">
      <InputMask
        ref={maskInputRef}
        mask={mask}
        value={maskedValue}
        readOnly={readOnly}
        onChange={(e) => setMaskedValue(e.target.value)}
        style={{
          width: inputWidth
        }}
      />
      {/* Hidden Span to get the width */}
      <span ref={spanRef} className="hidden-span" style={{ visibility: 'hidden', whiteSpace: 'pre' }}>
        {maskedValue || ' '}
      </span>
    </div>
  );
};

export default AutoWidthInput;
