import React from 'react';
import i18n from '../../i18n';
import parse from 'html-react-parser';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import moment from 'moment';
import { useContext } from "../../context";

const PrintSetupInstructionsPage = () => {
    const { state: { config: { pei_portal_link } }} = useContext();
    const location: any = useLocation();
    const queryParams: any = queryString.parse(location?.search);
    
    return (
        <div className='print-setup-instructions'>
            <div className="header">
                <h1>{i18n.t("PrintPage.Header.title")}</h1>
                <div className="subtitle">
                    <p>{parse(i18n.t("PrintPage.Header.Subtitle.account-user", { first_name: queryParams?.first_name }))}</p>
                    <p className="box">{parse(i18n.t("PrintPage.Header.Subtitle.expiry", { expiry_date: moment(Number(queryParams?.expiry_date)).format('MMM D, YYYY')}))}</p>
                </div>
            </div>

            <section className="content">
                <p>{i18n.t("PrintPage.Content.steps-title")}</p>

                <ul>
                    <li>
                        <div className="stepIndex">1</div>
                        <p>{parse(i18n.t("PrintPage.Content.step-1", { myPEI: pei_portal_link }))}</p>
                    </li>
                    <li className="step2">
                        <div className="stepIndex">2</div>
                        {parse(i18n.t("PrintPage.Content.step-2"))}
                        <p className="box">{parse(i18n.t("PrintPage.Content.verification-code", { code: queryParams?.code }))}</p> 
                    </li>
                    <li>
                        <div className="stepIndex">3</div>
                        <p>{i18n.t("PrintPage.Content.step-3")}</p> 
                    </li>
                    <li>
                        <div className="stepIndex">4</div>
                        <p>{i18n.t("PrintPage.Content.step-4")}</p> 
                    </li>
                </ul>
            </section>       
        </div>
    )
}

export default PrintSetupInstructionsPage;