import { useMemo } from "react";
import { usePost, useTriggeredPost, useGet, useTriggeredGet } from "./";

// Search User
const useSearch = (searchData: any) => {
  const { response, error, isLoading } = useGet({
    url: `/admin/person?rin=${searchData?.rin}${
      searchData?.email ? `&email=${searchData?.email}` : ""
    }`,
  });
  const search = useMemo(() => response || {}, [response]);
  return { search, error, isLoading };
};

const useTriggeredSearch = (searchData: any) => {
  const { endpoint: useSearch, isLoading } = useTriggeredGet({
    url: `/admin/person?rin=${searchData?.rin}${
      searchData?.email ? `&email=${encodeURIComponent(searchData?.email)}` : ""
    }`,
  });
  return { useSearch, isLoading };
};

// Fetch User Details
const useGetPersonDetails = (user_id?: string) => {
  const { response, error, isLoading } = useGet({
    url: `/admin/person/${user_id}`,
  });
  const details = useMemo(() => response || {}, [response]);
  return { details, error, isLoading };
};

const useTriggeredGetPersonDetails = (user_id?: string) => {
  const { endpoint: useGetPersonDetails, isLoading } = useTriggeredGet({
    url: `/admin/person/${user_id}`,
  });
  return { useGetPersonDetails, isLoading };
};

export const useTriggeredReset2FA = (user_id: string) => {
  const { endpoint: reset2FA, isLoading } = useTriggeredPost({
    url: `/admin/person/${user_id}/2fa-reset`,
  });
  return { reset2FA, isLoading };
};

export const useTriggeredResetEmail = (user_id: string) => {
  const { endpoint: resetEmail, isLoading } = useTriggeredPost({
    url: `/admin/person/${user_id}/email`,
  });
  return { resetEmail, isLoading };
};

// Start Verification
const useStartVerification = () => {
  const { response, error, isLoading } = usePost({
    url: "/admin/verification",
  });
  const result = useMemo(() => response || {}, [response]);
  return { result, error, isLoading };
};

const useTriggeredStartVerification = () => {
  const { endpoint: useStartVerification, isLoading } = useTriggeredPost({
    url: "/admin/verification",
  });
  return { useStartVerification, isLoading };
};

// Start Health Card Verification
const useTriggeredStartHelathCardVerification = () => {
    const { endpoint: useGenerateIDVHealthCardLink, isLoading } = useTriggeredPost({ url: `/admin/person/hc` });
    return { useGenerateIDVHealthCardLink, isLoading };
}

// Fetch verification Url
const useFetchVerification = (verification_id?: string) => {
  const { response, error, isLoading } = useGet({
    url: `/admin/verification/${verification_id}`,
  });
  const result = useMemo(() => response || {}, [response]);
  return { result, error, isLoading };
};

const useTriggeredFetchVerification = (verification_id?: string) => {
  const { endpoint: useFetchVerification, isLoading } = useTriggeredGet({
    url: `/admin/verification/${verification_id}`,
  });
  return { useFetchVerification, isLoading };
};

// Fetch Validation Broker Data
const useFetchValidationBrokerData = (user_id?: string) => {
  const { response, error, isLoading } = useGet({
    url: `/admin/person/${user_id}/update`,
  });
  const result = useMemo(() => response || {}, [response]);
  return { result, error, isLoading };
};

const useTriggeredFetchValidationBrokerData = (user_id?: string) => {
  const { endpoint: useFetchValidationBrokerData, isLoading } = useTriggeredGet(
    { url: `/admin/person/${user_id}/update` }
  );
  return { useFetchValidationBrokerData, isLoading };
};

// Get Verification Code Details for Print
const useFetchVerificationCode = (verification_id: string) => {
  const { response, error, isLoading } = useGet({
    url: `/admin/verification/${verification_id}/verification-code`,
  });
  const result = useMemo(() => response || {}, [response]);
  return { result, error, isLoading };
};

const useTriggeredFetchVerificationCode = (verification_id?: string) => {
  const { endpoint: useFetchVerificationCode, isLoading } = useTriggeredGet({
    url: `/admin/verification/${verification_id}/verification-code`,
  });
  return { useFetchVerificationCode, isLoading };
};

export {
    useStartVerification,
    useTriggeredStartVerification,
    useSearch,
    useTriggeredSearch,
    useFetchVerification,
    useTriggeredFetchVerification,
    useGetPersonDetails,
    useTriggeredGetPersonDetails,
    useFetchValidationBrokerData,
    useTriggeredFetchValidationBrokerData,
    useFetchVerificationCode,
    useTriggeredFetchVerificationCode,
    useTriggeredStartHelathCardVerification
}
