import * as React from 'react';
import { 
  ConfigReducer
} from './reducers';
import { setFromLocal } from "./actions/ConfigActions";

const Context = React.createContext();

const initialState = {
  config: {}, 
  profile: null, 
  error: {hasError: false, type: ""}
};

// Each Reducer handling only a piece of state
function reducer(state, action) {
  return {
    ...ConfigReducer(state, action),
  };
}

function ContextProvider({ children }) {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const key = 'admin';
  React.useEffect(() => {
    // get sessionStorage
    let item = window.sessionStorage.getItem(key) ? JSON.parse(window.sessionStorage.getItem(key)) : initialState;
    dispatch(setFromLocal(item))
  }, [])

  // when state changes, update sessionStorage
  React.useEffect(() => {
    const {config, profile, token} = state;
    const updatedStated = { config, token, profile };
    window.sessionStorage.setItem(key, JSON.stringify(updatedStated));
  }, [state])

  const contextValue = React.useMemo(() => ({ state, dispatch }), [state, dispatch]);
  
  return <Context.Provider value={contextValue}>{children}</Context.Provider>
}

function useContext() {
  return React.useContext(Context);
}

export { ContextProvider, useContext, reducer }
