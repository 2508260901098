import React from 'react';
import { NotificationI } from './ComponentInterface';
import { ReactComponent as Close } from '../asset/icon-white-close.svg';

const Notification = ({ icon, text, className, canClose, closeAction, dataTestId }: NotificationI) => (
    <div className={`notification ${icon ? 'icon': ''} ${className ? className : ''}`} data-testid={dataTestId}>
        <>
            {icon}
            <p>{text}</p>
        </>

        {
            canClose
            ?
            <button onClick={closeAction} className='close-icon' data-testid="button-close"><Close /></button>
            :
            null
        }
    </div>
)

export default Notification;
