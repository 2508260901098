import React from "react";
import { NavLink } from "react-router-dom";
import { Spinner } from "..";
import { ButtonI } from "../ComponentInterface";

const Button = ({
  url,
  text,
  disabled,
  onClick,
  isLoading,
  className,
  dataTestId,
  type = "button",
}: ButtonI) => {
  if (isLoading) {
    return (
      <button
        type={type}
        data-testid={dataTestId}
        className={`primary-btn ${className ? className : ""}`}
      >
        <Spinner />
      </button>
    );
  }
  if (!onClick) {
    return (
      <NavLink
        data-testid={dataTestId}
        className={`primary-btn ${disabled ? "disabled" : ""} ${
          className ? className : ""
        }`}
        to={url || "/"}
        tabIndex={0}
      >
        {text}
      </NavLink>
    );
  } else {
    return (
      <button
        type={type}
        data-testid={dataTestId}
        className={`primary-btn ${disabled ? "disabled" : ""} ${
          className ? className : ""
        }`}
        tabIndex={0}
        onClick={onClick}
      >
        {text}
      </button>
    );
  }
};

export default Button;
