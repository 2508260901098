import React, { FC } from "react";
import { ReactComponent as Flag } from "../../../asset/icon_flag.svg";
import i18n from "../../../i18n";

type FlagHandlerProps = {
  flagText?: string;
};

const FlagHandler: FC<FlagHandlerProps> = ({ flagText }) => {
  if (!flagText || flagText === "normal") return <></>;
  const getFlagText = () => {
    const resetType = flagText.includes("email")
      ? i18n.t("email")
      : i18n.t("2FA");
    const accountLocked = !!flagText.includes("locked");
    return accountLocked
      ? i18n.t("account-locked-item", { item: resetType })
      : i18n.t("item-reset", { item: resetType });
  };
  return (
    <tr>
      <td colSpan={7} className="account-state">
        <div>
          <Flag />
          <span>{getFlagText()}</span>
        </div>
      </td>
    </tr>
  );
};

export default React.memo(FlagHandler);
