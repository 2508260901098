import React from "react";
import ReactModal from "react-modal";
import { ReactComponent as Close } from "../asset/icon-close.svg";
import { ModalI } from "./ComponentInterface";

const ModalComp = ({
  children,
  toggleModal,
  isModalOpen,
  canClose = true,
  className,
}: ModalI) => (
  <ReactModal isOpen={isModalOpen} className="modal">
    {canClose && (
      <div className="modal-header">
        <button data-testid={"button-close"} onClick={toggleModal}>
          <i>{<Close />}</i>
        </button>
      </div>
    )}
    <div className={`modal-container ${className ? className : ""}`}>
      {children}
    </div>
  </ReactModal>
);

export default ModalComp;
