import { ButtonHTMLAttributes } from "react";
import { onClickType } from "./props";

type TextContent = string | React.ReactElement | React.ReactElement[];

export interface HeaderI {
  title?: string;
  className?: string;
  showLogout?: boolean;
}

export interface LayoutI {
  children?: React.ReactNode;
}

export interface StatePageI {
  stateId?: string;
  successFunc?: (...params: any) => void;
}

export interface ErrorI {
  hasError?: boolean;
  type?: string;
  error?: any;
  errorBtnAction?: any;
}

export interface PageI {
  successFunc?: (...params: any) => void;
  goBackLink?: any;
  otherRoutes?: any;
  startActivityRoute?: any;
  cancelHandoff?: any;
  className?: string;
  currentActivity?: ActivityI;
}

export interface SpinnerI {
  dark?: boolean;
}

export interface ButtonI {
  type?: ButtonHTMLAttributes<HTMLButtonElement>["type"];
  className?: string;
  disabled?: boolean;
  isLoading?: boolean;
  onClick?: onClickType;
  text?: TextContent;
  url?: string;
  error?: {
    type?: string;
    hasError?: boolean;
  };
  dataTestId?: string;
}

export interface IconButtonI {
  icon?: TextContent;
  onClick?: onClickType;
  id?: string;
  type?: string;
}

export interface LinkButtonI {
  className?: string;
  onClick?: onClickType;
  text?: TextContent;
  url?: string;
  dataTestId?: string;
}

export interface ActivityI {
  type?: string;
  identifier: string;
}

export interface ModalI {
  canClose?: boolean;
  children?: any;
  className?: string;
  isModalOpen: boolean;
  toggleModal?: onClickType;
}

export interface IWindow {
  config: ConfigI;
}

export enum Features {
  Reset2FA = "reset_2fa",
  ResetEmail = "reset_email",
}

export interface ConfigI {
  base_url?: string;
  favicon?: string;
  pei_portal_link?: string;
  feature_flags_enabled?: { [key in Features]?: boolean };
}

export interface TextInputI {
  id?: string;
  value?: string;
  infoClick?: onClickType | undefined;
  isError?: boolean;
  key?: string;
  label?: string;
  labelDescription?: string | JSX.Element | JSX.Element[];
  maxLength?: number;
  minLength?: number;
  onChange?: (e: any, i: any) => void;
  onValidate?: any;
  optional?: boolean;
  placeholder?: string;
  readOnly?: boolean;
  required?: boolean;
  type?: string;
  showCtaIcon?: boolean;
  hideCta?: boolean;
  editClick?: onClickType;
  ctaBtnText?: string;
  name?: string;
  isFocused?: Boolean;
  className?: string;
  showCTA?: boolean;
  onBlur?: () => void;
  errorMessage?: string;
  dataTestId?: string;
  errorDataTestId?: string;
}

export interface SearchInputI {
  rin: string;
  email?: string;
}

export interface SearchResultI {
  email?: string;
  verification_status?: string;
  verification_id?: string;
  first_name?: string;
  last_name?: string;
  account_status?: string;
  birth_date?: string;
  duration?: number;
  rin?: string;
  id_card_number?: string;
  user_id?: string;
  "2fa_reset"?: string;
  email_reset?: string;
  reset_status?: string;
  authenticator?: "TOTP" | "SMS" | "EMAIL";
}

export interface NotificationI {
  className?: string;
  icon?: TextContent;
  text: TextContent;
  canClose?: boolean;
  closeAction?: () => void;
  dataTestId?: string;
}
