import React from 'react';
import {
  Route,
  Redirect,
} from 'react-router-dom';
import {
  useContext
} from '../context'
import {
  updateError,
} from '../context/actions/ConfigActions';
import { SESSION_TIMEOUT } from './Constants';

const scrollTop = () => window.scrollTo(0, 0);

const ProtectedRoute = ({ render, ...rest }: any) => {
  const { state, dispatch } = useContext();

  if (!state.profile) {
    dispatch(updateError({ hasError: true, type: SESSION_TIMEOUT }));
    return <Redirect to={{ pathname: window.GLOBAL_PATH }} />
  } else {
    return <Route {...rest} render={(props) => { return render(props) }} />
  }
}

export const DefaultRoute = ({
  ...rest
}) => (
  <Route
    {...rest}
    render={() => <Redirect to={{ pathname: window.GLOBAL_PATH }} />}
  />
);

export const PublicRoute = ({ render, ...rest }: any) => {
  return <Route {...rest} render={(props) => { scrollTop(); return render(props) }} />
}

export default ProtectedRoute;