import React from 'react';

export const validateTextInput = (event: React.ChangeEvent<HTMLInputElement>, maxLength: number, minLength: number) => {
    return Boolean(event.target.value && event.target.value.length >= minLength && event.target.value.length <= maxLength).valueOf();
};

export const validatePassword = (event: React.ChangeEvent<HTMLInputElement>, maxLength: number, minLength: number) => {
    const regext = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W)/;

    if (!(regext.test(String(event.target.value)))) return false;
    return Boolean(event.target.value && event?.target.value.length >= minLength && event?.target.value.length <= maxLength).valueOf();
};

export const validateCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    return Boolean(event.target.checked === true).valueOf();
};

export const validateDropdown = (event: React.ChangeEvent<HTMLInputElement>) => {
    return Boolean(event).valueOf()
};

export const validateEmail = (email: string) => {
    const regext = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z-\d]+\.)+[a-zA-Z]{2,}))$/;

    return regext.test(String(email).toLowerCase());
};

export const validateNumber = (value: string) => {
    const regext = /^\d+$/;

    return regext.test(String(value));
};

export const validateNumberInput = (event: React.ChangeEvent<HTMLInputElement>, length?: number) => {
    return Boolean(event).valueOf() && event.target.value.length === length
};

export const validatePasscode = (event: string) => {
    return Boolean(event && event.length === 6).valueOf();
};
