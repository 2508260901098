import React, { useEffect, useState } from "react";
import { Spinner } from "../../../components";
import { useContext } from "../../../context";
import { updateError } from "../../../context/actions/ConfigActions";
import i18n from "../../../i18n";
import { useTriggeredGetPersonDetails } from "../../../services/AdminServices";
import { API_ERROR, SESSION_TIMEOUT } from "../../../utils/Constants";
import { updatedDataFromValidator } from "../../../utils/SearchPage";
import DataEntry from "./DataEntry";
import useSearchTableLayout from "../hooks/useSearchTableLayout";
import { SearchResultI } from "../../../components/ComponentInterface";

const AUTHENTICATOR_MAP = {
  EMAIL: "Email",
  SMS: "SMS",
  TOTP: "Authenticator App",
};

const replaceAuthenticator = (result: SearchResultI) => {
  if (!result?.authenticator || !!result["2fa_reset"]) return { authenticator: "" };
  if (Object.keys(AUTHENTICATOR_MAP).includes(result.authenticator))
    return { authenticator: AUTHENTICATOR_MAP[result.authenticator] };
  return { authenticator: result.authenticator };
};

const SearchItemDetails = ({
  searchData,
  validationBrokerData,
  handleEditClick = () => {},
}: {
  searchData?: SearchResultI;
  validationBrokerData?: any;
  handleEditClick?: (key: string) => void;
}) => {
  const {
    state: { error },
    dispatch,
  } = useContext();
  const { LAYOUT, MAX_ROWS } = useSearchTableLayout();
  const [verificationDetails, setVerificationDetails] = useState<any>({});

  const { useGetPersonDetails: getPersonDetails, isLoading } =
    useTriggeredGetPersonDetails(searchData?.user_id);

  // Update Data from Validator
  useEffect(() => {
    const dataFromValidator = () => {
      const result = updatedDataFromValidator(
        verificationDetails,
        validationBrokerData
      );
      setVerificationDetails({ ...result, ...replaceAuthenticator(result) });
    };

    if (Object.keys(validationBrokerData).length) {
      dataFromValidator();
    }

    // eslint-disable-next-line
  }, [validationBrokerData]);

  useEffect(() => {
    // GEt DEtails
    const getDetails = async () => {
      if (!error.hasError) {
        const { response, error } = await getPersonDetails();

        if (response.isSuccess) {
          setVerificationDetails({
            ...response?.data,
            ...replaceAuthenticator(response?.data),
          });
        }

        if (Object.keys(error).length) {
          if (error.isUnauthorizedError || error.isForbiddenError) {
            dispatch(updateError({ hasError: true, type: SESSION_TIMEOUT }));
          } else {
            dispatch(updateError({ hasError: true, type: API_ERROR }));
          }
        }
      }
    };

    getDetails();

    // eslint-disable-next-line
  }, [error.hasError]);

  return (
    <div className="search-Details">
      {isLoading ? (
        <Spinner dark />
      ) : (
        <>
          {Object.keys(verificationDetails).length ? (
            <div className="container">
              <div className="content">
                <table className="details-table">
                  <thead>
                    <tr>
                      {LAYOUT.map((column, columnIndex) => (
                        <th key={`head-column-${columnIndex}`}>
                          {i18n.t(column.title)}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {Array.from({ length: MAX_ROWS }, (_, i) => i).map(
                      (rowIndex) => (
                        <tr key={`body-row-${rowIndex}`}>
                          {LAYOUT.map((column, columnIndex) => (
                            <DataEntry
                              key={`body-column-${columnIndex}`}
                              editable={
                                searchData?.account_status === "ACTIVE" &&
                                column.rows[rowIndex]?.editable
                              }
                              onEdit={() =>
                                handleEditClick(column.rows[rowIndex]?.label)
                              }
                              key_string={column.rows[rowIndex]?.label}
                              value={String(
                                verificationDetails?.[
                                  column.rows[rowIndex]?.label
                                ] || ""
                              )}
                            />
                          ))}
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <p className="no-details-error">
              {i18n.t("SearchPage.no-details-error")}
            </p>
          )}
        </>
      )}
    </div>
  );
};

export default SearchItemDetails;
