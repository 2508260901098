import { useContext } from '../../../context'
import { updateError } from '../../../context/actions/ConfigActions'
import { useTriggeredStartHelathCardVerification } from '../../../services/AdminServices'
import { API_ERROR, ERRORS } from '../../../utils/Constants'

export default function useHealthCardVerification(user_id) {
  const { useGenerateIDVHealthCardLink: generateIDVHealthCardLink, isLoading } = useTriggeredStartHelathCardVerification()
  const { dispatch } = useContext()

  const redirectionIDVHealthCard = async () => {
    try {
      const { response, error } = await generateIDVHealthCardLink({ data: { user_id } })

      if (response?.isSuccess) {
        const {
          data: { link },
        } = response
        window.open(link, '_blank')
      } else {
        const {
          response: { status },
        } = error

        switch (status) {
          case 401:
            dispatch(updateError({ hasError: true, type: ERRORS.USER_UNVERIFIED }))
            break
          case 403:
            dispatch(updateError({ hasError: true, type: ERRORS.HEALTH_CARD_EXISTS }))
            break

          default:
            dispatch(updateError({ hasError: true, type: API_ERROR }))
        }
      }
    } catch (error) {
      dispatch(updateError({ hasError: true, type: API_ERROR }))
    }
  }

  return { redirectionIDVHealthCard, isLoadingHC: isLoading }
}
