import React, { useEffect, useState } from 'react';
import {
  ModalComp,
  Button,
  Spinner,
  Notification
} from "../../components";
import i18n from '../../i18n';
import { Helmet } from "react-helmet";
import parse from 'html-react-parser';
import { PageI } from '../../components/ComponentInterface';
import { useContext } from '../../context';
import { 
    updateError 
} from "../../context/actions/ConfigActions";
import { ReactComponent as Alert } from '../../asset/icon_alert_white.svg';
import { OAUTH_PROVIDERS_ERROR, API_ERROR, SESSION_TIMEOUT } from "../../utils/Constants";
import { useTriggeredOauthProviders } from "../../services/LoginServices";

const LoginPage = ({ successFunc }: PageI) => {
    const { state: { error, profile, config }, dispatch } = useContext();

    const { useOauthProviders: oauthProviders, isLoading } = useTriggeredOauthProviders();

    const [showErrorNotification, setShowErrorNotification] = useState(false);
    const [notificationError, setNotificationError] = useState("");
    const [isModalOpen, setModalOpen] = useState<boolean>(false);
   
    const [oauthProvidersList, setOauthProvidersList] = useState([]);
    
    // If Profile is there directly go to Dashboard page
    useEffect(() => { 
        if (profile?.name) {
            return successFunc!()
        }
        // eslint-disable-next-line
      }, [profile?.name]);
    
    // Fetch Outh Providers
    useEffect(() => { 
        const getOauthProvidersList = async () => {
            try {
                if (!oauthProvidersList.length && !profile?.name) {
                    const { response, error: api_error } = await oauthProviders();
                    
                    if (response.isSuccess) {
                        setOauthProvidersList(response?.data);
                    } 
    
                    if (Object.keys(api_error).length) {
                        if (api_error.isNotFoundError) {
                            dispatch(updateError({ hasError: true, type: OAUTH_PROVIDERS_ERROR }));
                        } else {
                            dispatch(updateError({ hasError: true, type: API_ERROR }));
                        }
                    }
                };  
            } catch (err) {
                dispatch(updateError({ hasError: true, type: API_ERROR }));
            }
        }

        getOauthProvidersList();
        
        // eslint-disable-next-line
    }, [oauthProvidersList, profile?.name]);
    
    const handleLoginClickAction = async (item: { identifier?: string, name?: string }) => { 
        dispatch(updateError({ hasError: false, type: "" }));
       
        // Redirect to IDP
        window.location.href = `${config.base_url}/admin/login/idps/${item?.identifier}`;
    }

    const handleErrorModal = () => {
        setModalOpen(!isModalOpen);
    }

    // handling NotificationCLose
    const handleNotificationError = () => {
        setShowErrorNotification(false);
        dispatch(updateError(""));
        setNotificationError("");
    }

    // Error Notifications
    useEffect(() => {
        if (error?.hasError === true) { 
            if (error?.type === SESSION_TIMEOUT) {
                setShowErrorNotification(true);
                setNotificationError("session-timeout-error");
            }
    
            if (error?.type === "login-error") {
                setShowErrorNotification(true);
                setNotificationError("login-error");
            }
    
            if (error?.type === OAUTH_PROVIDERS_ERROR) {
                setShowErrorNotification(true);
                setNotificationError("unknown-oauth-error");
            }
        }
        // eslint-disable-next-line 
    }, [error]);

    if (profile?.name) {
        return null;
    }

    return (
        <div className='loginpage'>
            <Helmet>
                <meta charSet={i18n.t("charset")} />
                <link href={i18n.t('favicon')} />
                <title>{(`${i18n.t('LoginPage.title')}`).toString()}</title>
                <noscript>{i18n.t("noscript").toString()}</noscript>
            </Helmet>
            
            <div className="sidebar">
                <div className='logo' data-testid="application-name">
                    { parse(i18n.t('logo')) }
                </div>
            </div>
            
            <section>
                {
                    showErrorNotification
                    ?
                    <Notification className="error" text={parse(i18n.t(notificationError))} icon={<Alert />} canClose={true} closeAction={handleNotificationError} dataTestId={notificationError} />
                    :
                    null
                }
                
                <div className="login-container">
                    <div className="header">
                        <hr />
                        <h3>{`${i18n.t('LoginPage.title')}`}</h3>
                    </div>

                    {/* Oauth Providers List */}

                    {
                        isLoading
                        ?
                        <Spinner dark />
                        :
                        <div className='btns-container'>
                            {
                                oauthProvidersList.length
                                ?
                                oauthProvidersList.map((item: any) => (           
                                    <Button className={`login-btn`} onClick={() => handleLoginClickAction(item)} key={item?.identifier} dataTestId={`idp-${item?.name}`} text={`${i18n.t('LoginPage.login-with')} ${item?.name}`} />
                                ))
                                :
                                null
                            }
                        </div>
                    }
                </div>
            </section>

            <ModalComp toggleModal={handleErrorModal} isModalOpen={isModalOpen} canClose={false}>
                <Alert />
                { parse(i18n.t('generic-error-modal')) }

                <Button text={i18n.t('try-again')} onClick={handleErrorModal} />
            </ModalComp>
        </div>
    )
}

export default LoginPage;