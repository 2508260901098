import React from 'react';
import { LinkButton } from "../components";
import i18n from '../i18n';
import { HeaderI } from './ComponentInterface';
import { Helmet } from "react-helmet";
import { useContext } from '../context';
import { updateProfile, updateError } from '../context/actions/ConfigActions';
import { useHistory } from 'react-router-dom';
import { useTriggeredLogout } from "../services/LoginServices";
import { SESSION_TIMEOUT, API_ERROR } from "../utils/Constants";

const Header = ({ title, showLogout, className }: HeaderI) => {
    const history = useHistory();
    const { state: { config: { favicon }, profile }, dispatch } = useContext();
    const { useLogout: logoutApi } = useTriggeredLogout();

    const logoutClick = async () => {
        try { 
            const { response, error: api_error } = await logoutApi();

            if (response.isSuccess) {
                history.push(window.GLOBAL_PATH);
                dispatch(updateProfile(null));
            }

            if (Object.keys(api_error).length) {  
                if (api_error.isUnauthorizedError || api_error.isForbiddenError) {
                    dispatch(updateError({ hasError: true, type: SESSION_TIMEOUT }));
                } else {
                    dispatch(updateError({ hasError: true, type: API_ERROR }));
                }
            }           
        } catch (e) {
            console.log('e', e);
            dispatch(updateError({ hasError: true, type: API_ERROR }));
        }
    }
    
    return (
        <header className={`${className || ''}`}>
            <Helmet>
                <meta charSet={i18n.t("charset")} />
                <link href={i18n.t('favicon')} />
                <title>{title}</title>
                <noscript>{i18n.t("noscript").toString()}</noscript>
                <link rel="shortcut icon" href={favicon} />
            </Helmet>

            <div className='header-container'>
                {
                    title
                    ?
                    <h1 data-testid="application-name">{title}</h1>
                    :
                    null
                }

                {
                    showLogout
                    &&
                    <div className="right-section">
                        <p data-testid="reviewer-name">{profile?.name}</p>
                        <LinkButton onClick={logoutClick} text={i18n.t('logout')} className='logout' dataTestId="logout" />
                    </div>
                }
            </div>
        </header>
    )
}

export default Header;
