import React, {
  useState,
  useEffect,
} from 'react';
import {
  Button,
  TextInput
} from '..';
import i18n from '../../i18n';
import { validateEmail, validateNumber } from '../validate';
  
  const SearchForm = ({ successFunc, isLoading, isSearchingSetState, errorObj, setErrorObj, inputs, setInputs }: {successFunc?: (params?: any) => void, isLoading?: boolean, isSearchingSetState?: any, errorObj?: any, setErrorObj?: any, inputs?: any,setInputs?: any }) => {
    const [isValidForm, setIsValidForm] = useState<boolean>(false);
   
    useEffect(() => {
      if (isValidForm) {
        successFunc!(inputs)
        setIsValidForm(false);
      }

      // eslint-disable-next-line 
    }, [isValidForm]);

    const handleOnChange = (e: any) => {
      const { name, value } =  e?.target;

      setInputs({ ...inputs, [name]: value.trim() })

      if (errorObj?.[name]) {
        const updatedErrorObj = { ...errorObj };
        delete updatedErrorObj[name];
        setErrorObj(updatedErrorObj);
      }
    }

    const submitClick = () => {
      const isValid: boolean = handleValidations();

      setIsValidForm(isValid);
    }

    const handleClearForm = () => {
      setInputs({ rin: '', email: '' });
      setErrorObj({});
      isSearchingSetState(false);
    }

    const handleValidations = () => {
      let error: any = [];

      // Required Check
      if (!inputs?.rin) {
        error.push({ field: 'rin', hasError: true, type: 'SearchPage.ErrorMessages.required-field' });
      }

      // Valid Email Check
      if (inputs?.email && !validateEmail(inputs?.email)) {
       error.push({ field: 'email', hasError: true, type: 'SearchPage.ErrorMessages.invalid-email' });
      }

      // Valid rin Check
      if (inputs?.rin && !validateNumber(inputs?.rin)) {
        error.push({ field: 'rin', hasError: true, type: 'SearchPage.ErrorMessages.invalid-number' });
      }
      
      if (error.length) {
        error.map((item: any) => {
          setErrorObj((prevInputs: any) => ({
              ...prevInputs,
              [item.field]: {
                  hasError: item?.hasError,
                  errorMessage: item?.type
              }
          }));

         return item;
        })

        return false;
      }

      return true;
    };
   
    return (
        <>
            <form onSubmit={e => e.preventDefault()} className='search-form' >
                <TextInput type='rin' name="rin" onChange={handleOnChange} value={inputs.rin || ''} maxLength={12} label={i18n.t('SearchPage.SearchLabels.id_card_number')} isFocused={Boolean(inputs?.rin)} isError={errorObj["rin"]} errorMessage={errorObj["rin"]?.errorMessage} required dataTestId={"id-card-num"} errorDataTestId={"id-card-num-error"} />
        
                <TextInput type={'email'} name="email" onChange={handleOnChange} value={inputs.email || ''} maxLength={150} minLength={10} label={i18n.t('SearchPage.SearchLabels.email')} isFocused={Boolean(inputs.email)} isError={errorObj["email"]} errorMessage={errorObj["email"]?.errorMessage} className="email-textform" dataTestId={"email-address"} errorDataTestId={"email-error"} />
        
                <Button onClick={submitClick} text={i18n.t('search')} isLoading={isLoading} dataTestId={"button-search"} />
                <Button className="border-btn" onClick={() => handleClearForm()} text={i18n.t('clear')} dataTestId={"button-clear"} />
            </form>
        </>
    )
  }
  
  export default SearchForm;
  