const ConfigReducer = (state: any, action: any) => {
    switch (action.type) {
      case 'SET_FROM_LOCAL':
        return { ...state, ...action.payload };
      case 'UPDATE_CONFIG':
        return { ...state, config: action.payload };
      case 'UPDATE_ERROR':
        return { ...state, error: action.payload };
      case 'UPDATE_TOKEN': 
        return { ...state, token: action.payload }
      case 'UPDATE_PROFILE':
        return { ...state, profile: action.payload };
      default:
        return state;
    }
}

export default ConfigReducer;