import React from 'react';
import { IconButtonI } from '../ComponentInterface';

const IconButton = ({ onClick, id, icon }: IconButtonI) => (
    <button title='info-btn' className="info-btn" onClick={(e) => { onClick && onClick(e, id) }}>
        <i>{icon}</i>
    </button>
)

export default IconButton;
