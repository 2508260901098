import React from 'react';
import i18n from '../../i18n'
import IconButton from '../buttons/IconButton';
import { ReactComponent as Info } from '../../asset/icon-question.svg';
import { TextInputI } from '../ComponentInterface';

const TextInput = ({ value, onChange, maxLength, minLength, optional, label, showCtaIcon, infoClick, type, readOnly = false, placeholder, required, isError, labelDescription, editClick, ctaBtnText, name, isFocused, hideCta, className, errorMessage, dataTestId, errorDataTestId }: TextInputI) => {
    
    const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
            onChange(e, e.target.value.slice(0, maxLength));
        }
    };
    
    return (
        <div className={`textbox-form ${readOnly ? 'read-only' : ''} ${className || ''}`}>
            {
                label
                &&
                <label htmlFor={label}><strong>{label}</strong> {labelDescription} {optional && <span>{`${i18n.t('optional')}`}</span>} {(!readOnly && required) && <strong>*</strong>}</label>
            }
            <div className={`textbox-form-input ${infoClick ? 'textbox-form-input-info' : ''} ${isError ? 'error' : ''} ${(isFocused && !readOnly && !isError) ? 'active-border' : ''}`}>
                <input
                    onChange={onInputChange}
                    type="text"
                    name={name}
                    maxLength={maxLength}
                    minLength={minLength}
                    readOnly={readOnly}
                    placeholder={placeholder}
                    value={value}
                    id={label}
                    formNoValidate
                    data-testid={dataTestId}
                />

                {   
                    infoClick && !readOnly && !hideCta && (<IconButton onClick={(e: any) => infoClick!(e, type)} icon={<Info />} />)
                }
                {
                    showCtaIcon && (<IconButton onClick={(e: any) => infoClick!(e, type)} icon={<Info />} />)
                }
                {
                    ctaBtnText && (<button className="info-btn text-btn" onClick={editClick}>{ctaBtnText}</button>)
                }   

                { 
                    (isError && errorMessage)
                    ?
                    <p className='error-msg' data-testid={errorDataTestId}>{`${i18n.t(errorMessage)}`}</p>
                    :
                    null
                }
            </div>
        </div>
    )
};

export default TextInput;
